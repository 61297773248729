import React from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Boule from "../Boule/Boule";
import moment from "moment";
import loto from "../../loto.json";

const rowRenderer = ({ index, style }) => (
  <div style={style}>
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {`${moment(loto[index].date).format("DD-MM-YYYY")} :`}
      <Boule numero={loto[index].boule1} />
      <Boule numero={loto[index].boule2} />
      <Boule numero={loto[index].boule3} />
      <Boule numero={loto[index].boule4} />
      <Boule numero={loto[index].boule5} />
      {loto[index].boule6?<Boule numero={loto[index].boule6} />:<React.Fragment/>}
    </div>
  </div>
);

const Tirages = () => (
  <AutoSizer>
    {({ height, width }) => (
      <List
        className="List"
        height={45 * 8}
        itemCount={loto.length}
        itemSize={45}
        width={450}
        style={{display:'flex'}}
      >
        {rowRenderer}
      </List>
    )}
  </AutoSizer>
);
export default Tirages;
