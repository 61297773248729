import React from "react"
import Boule from "../Boule/Boule"
import moment from "moment"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection:'row',
        justifyContent: "center",
        alignItems: "center",
        flexWrap:'wrap'
    }
  });

const DernierTirage = ({ date, boule1, boule2, boule3, boule4, boule5, boule6 }) => {
    const classes = useStyles()
  return (
    <div
      className={classes.root}
    >
      {`${moment(date).format("DD-MM-YYYY")} :`}
      <Boule numero={boule1} />
      <Boule numero={boule2} />
      <Boule numero={boule3} />
      <Boule numero={boule4} />
      <Boule numero={boule5} />
      {boule6 ?<Boule numero={boule6} /> : <React.Fragment/>}
    </div>
  )
}
export default DernierTirage
