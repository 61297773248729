import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./App.styles";
import loto from "./loto.json";

import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Tirages from "./composants/Tirages/Tirages";
import Tirage from "./composants/Tirage/Tirage";
import DernierTirage from "./composants/DernierTirage/DernierTirage";
import Boule from "./composants/Boule/Boule";
import {
  computeTopFlop,
  numeroListe,
  recherheDate,
  rechercheNumero,
} from "./fonctions/fonctions";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as tf from "@tensorflow/tfjs";
import { predictLoto } from "./fonctions/tensor";

predictLoto();
moment.localeData("fr");

const topMoins = [];
const topPlus = [];

computeTopFlop(topMoins, topPlus);
const numeros = numeroListe();

const App = ({ classes }) => {
  const [naissanceDate, setNaissanceDate] = useState(new Date());
  const [selectedNumero1, setSelectedNumero1] = useState(numeros[0]);
  const [selectedNumero2, setSelectedNumero2] = useState(numeros[0]);
  const [selectedNumero3, setSelectedNumero3] = useState(numeros[0]);
  const [selectedNumero4, setSelectedNumero4] = useState(numeros[0]);
  const [selectedNumero5, setSelectedNumero5] = useState(numeros[0]);
  const [tirageNaissance, setTirageNaissance] = useState(new Date()); //recherheDate(naissanceDate)

  const numeroList = rechercheNumero(
    selectedNumero1,
    selectedNumero2,
    selectedNumero3,
    selectedNumero4,
    selectedNumero5
  );

  const handleDateChange = (date) => {
    setNaissanceDate(date);
    setTirageNaissance(recherheDate(date));
  };

  return (
    <div className={classes.App}>
      <header className={classes.AppHeader}>
        <h1>Loto : A qui le tour !</h1>
      </header>
      <div>
        <div id="container-principal" className={classes.ContainerPrincipal}>
          <div
            id="container-tirages-numero-top"
            className={classes.ContainerSecondaire}
          >
            <div id="tirages" className={classes.Tirages}>
              <h2>Tirages</h2>
              <Tirages />
            </div>
            <div
              id="numero"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "33%",
                height: "100%",
              }}
            >
              <h2>Recherche d'un numéro</h2>
              <div
                id="recherche-un-numero"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <FormControl style={{ width: 50, margin: "5px" }}>
                  <InputLabel id="input-numero-label">N°</InputLabel>
                  <Select
                    labelId="select-numero-label"
                    id="select-numero-id"
                    value={selectedNumero1}
                    onChange={(event) => setSelectedNumero1(event.target.value)}
                    MenuProps={{
                      onScroll: "loadMoreItems",
                    }}
                  >
                    {numeros.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 50, margin: "5px" }}>
                  <InputLabel id="input-numero-label">N°</InputLabel>
                  <Select
                    labelId="select-numero-label"
                    id="select-numero-id"
                    value={selectedNumero2}
                    onChange={(event) => setSelectedNumero2(event.target.value)}
                    MenuProps={{
                      onScroll: "loadMoreItems",
                    }}
                  >
                    {numeros.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 50, margin: "5px" }}>
                  <InputLabel id="input-numero-label">N°</InputLabel>
                  <Select
                    labelId="select-numero-label"
                    id="select-numero-id"
                    value={selectedNumero3}
                    onChange={(event) => setSelectedNumero3(event.target.value)}
                    MenuProps={{
                      onScroll: "loadMoreItems",
                    }}
                  >
                    {numeros.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 50, margin: "5px" }}>
                  <InputLabel id="input-numero-label">N°</InputLabel>
                  <Select
                    labelId="select-numero-label"
                    id="select-numero-id"
                    value={selectedNumero4}
                    onChange={(event) => setSelectedNumero4(event.target.value)}
                    MenuProps={{
                      onScroll: "loadMoreItems",
                    }}
                  >
                    {numeros.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 50, margin: "5px" }}>
                  <InputLabel id="input-numero-label">N°</InputLabel>
                  <Select
                    labelId="select-numero-label"
                    id="select-numero-id"
                    value={selectedNumero5}
                    onChange={(event) => setSelectedNumero5(event.target.value)}
                    MenuProps={{
                      onScroll: "loadMoreItems",
                    }}
                  >
                    {numeros.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 80, margin: "5px" }}>
                  <TextField value={numeroList.length} label="Occurence :" />
                </FormControl>
              </div>
              <div
                id="liste-boules"
                style={{
                  height: "200px",
                  width: "100%",
                  marginTop: "20px",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {numeroList.map((e) => (
                  <DernierTirage
                    date={e.date}
                    boule1={e.boule1}
                    boule2={e.boule2}
                    boule3={e.boule3}
                    boule4={e.boule4}
                    boule5={e.boule5}
                    boule6={e.boule6}
                  />
                ))}
              </div>
            </div>
            <div
              id="top-numeros"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "33%",
                height: "100%",
              }}
            >
              <h2>TOP et FLOP</h2>
              <div
                id="top-et-flop"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div id="top">
                  {topPlus.map((b) => (
                    <Boule numero={b.boule} />
                  ))}
                </div>
                <div id="flop">
                  {topMoins.map((b) => (
                    <Boule numero={b.boule} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            id="container-combinaison-naissance-dernier-tirage"
            className={classes.ContainerSecondaire}
          >
            <div id="tirages" className={classes.Section}>
              <h2>Inscris ta combinaison</h2>
            </div>
            <div id="numero" className={classes.Tirages}>
              <h2>Les numéros le jour de ta naissance</h2>
              <div style={{}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk={true}
                    invalidDateMessage="Date non valide"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date de Naissance"
                    value={naissanceDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "150px", marginRight: "15px" }}
                  />
                </MuiPickersUtilsProvider>
                {tirageNaissance?.boule1 ? (
                  <Tirage
                    boule1={tirageNaissance?.boule1}
                    boule2={tirageNaissance?.boule2}
                    boule3={tirageNaissance?.boule3}
                    boule4={tirageNaissance?.boule4}
                    boule5={tirageNaissance?.boule5}
                    boule6={tirageNaissance?.boule6}
                  />
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
            <div id="top-numeros" className={classes.Tirages}>
              <h2>Dernier tirage</h2>
              {console.log(loto)}
              <DernierTirage
                date={loto[loto.length - 1].date}
                boule1={loto[loto.length - 1].boule1}
                boule2={loto[loto.length - 1].boule2}
                boule3={loto[loto.length - 1].boule3}
                boule4={loto[loto.length - 1].boule4}
                boule5={loto[loto.length - 1].boule5}
                boule6={loto[loto.length - 1].boule6}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(App);
