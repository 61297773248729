import React from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./Boule.styles"
import PropTypes from "prop-types"

const Boule = ({ classes, numero, key }) => {
  return (
    <div key={key} className={classes.circle}>
      <span className={classes.text}>{numero}</span>
    </div>
  )
}

Boule.propTypes = {
  classes: PropTypes.object.isRequired,
  numero: PropTypes.number.isRequired,
}
export default withStyles(styles)(Boule)
