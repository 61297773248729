// inspiré de "https://ledatascientist.com/le-deep-learning-et-le-hasard-prediction-du-loto/"
import * as dataframe from "danfojs/src/index"
import lotoDatas from "../tensor.json"
import * as tf from "@tensorflow/tfjs"
import * as nj from "numjs"

// Params du modèle
const nb_label_feature = 6
const UNITS = 100
const BATCHSIZE = 30
const EPOCH = 1500
const OPTIMIZER = "adam" // rmsprop, adam, sgd
const LOSS = "categoricalCrossentropy" //"mae" // 'categorical_crossentropy' //mse
const DROPOUT = 0.1
const window_length = 12

const df = new dataframe.DataFrame(lotoDatas)

// Architecture du modèle
const define_model = (number_of_features, nb_label_feature) => {
  // initialisation du rnn
  const model = tf.sequential()
  // ajout de la premiere couche lstm
  model.add(
    tf.layers.lstm({
      units: UNITS,
      inputShape: [window_length, number_of_features],
      returnSequences: true,
    })
  )
  model.add(
    tf.layers.lstm({ units: UNITS, dropout: 0.1, returnSequences: false })
  )
  // ajout de la couche de sortie
  model.add(tf.layers.dense({ units: nb_label_feature }))

  model.compile({ loss: LOSS, optimizer: OPTIMIZER, metrics: "acc" })
  model.summary()
  return model
}

// Fonction de formatage des données en entrée du LSTM
export const create_lstm_dataset = (df, window_length, nb_label_feature) => {
  const number_of_rows = df.shape[0] // taille du dataset number_of_features
  const number_of_features = df.shape[1]

  const scaler = new dataframe.StandardScaler()//.fit(df.values)
  scaler.fit(df.values)
  const transformed_dataset = scaler.transform(df.values)

  // const transformed_df = new dataframe.DataFrame({
  //   data: transformed_dataset.values,
  //   index: df.index,
  // })

  let train = nj.zeros([
    number_of_rows - window_length,
    window_length,
    number_of_features,
  ])
  let label = nj.zeros([number_of_rows - window_length, nb_label_feature])

  for (let i = 0; i < number_of_rows - window_length - 1; i++) {
    train[i] = transformed_dataset.iloc({
      rows: [i, i + window_length],
      columns: [0, number_of_features - 1],
    })

    label[i] = transformed_dataset.iloc({
      rows: [i + window_length, i + window_length + 1],
      columns: [0, nb_label_feature],
    })
  }

  // définition du modèle Lstm
  const model = define_model(number_of_features, nb_label_feature)
  return { train, label, model, scaler }
}

// Moniteur pour stoper le training
//const es = EarlyStopping({monitor:'acc', mode:'max', verbose:1, patience:100})

export const predictLoto = async () => {
  // formatage des données
  const { train, label, model } = create_lstm_dataset(
    df,
    window_length,
    nb_label_feature
  )
  console.log(df.values)
  console.log(df.tensor)
  console.log(train.shape)
  console.log(label.shape)

  //Training
  const history = model.fit(train, label, {
    batch_size: BATCHSIZE,
    epochs: EPOCH,
    verbose: 2,
    callbacks: {
      onEpochEnd: async (epoch, logs) => {
        console.log(
          `EPOCH (${epoch + 1}): Train Accuracy: ${(logs.acc * 100).toFixed(
            2
          )},Val Accuracy:  ${(logs.val_acc * 100).toFixed(2)}\n`
        )
      },
    },
  })

  // Prediction basée sur les 12 derniers tirages
  //const last_twelve = df.tail(window_length) // on recupere les 12 derniers tirages
  //let scaler = new dataframe.StandardScaler().fit(df.values)
  //let scaled_to_predict = scaler.transform(last_twelve)
  //const scaled_predicted_output_1 = model.predict(scaled_to_predict)

  // prediction
  // const tom = df.tail(window_length).iloc({ rows: 0, columns: 6 }) //
  //scaler = dataframe.StandardScaler().fit(df.ilociloc({ rows: 0, columns: 6 }))
  //scaled_to_predict = scaler.transform(tom)
  //console.log(scaler.inverse_transform(scaled_predicted_output_1))
}
