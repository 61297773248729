export default (theme) => ({
  App: {
    textAlign: "center",
  },
  AppHeader: {
    backgroundColor: "#282c34",
    height: "12vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
  ContainerPrincipal: {
    background: "#FAF8FA",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: "24px",
    border: '1px solid'
  },
  ContainerSecondaire:{
    background: "#FFFAF5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    margin: "24px",
    border: '1px solid'
  }, 
  Tirages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "33%",
    display: "flex",
    flexWrap: "wrap",
  },
  Section:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "33%",
  }
})
