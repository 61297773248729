export default theme => ({
    circle:{
        margin:'10px',
        width:'30px',
        //backgroundColor:'#EA3946',
        background: 'radial-gradient(circle, rgba(95,95,176,1) 32%, rgba(0,162,217,1) 100%, rgba(0,212,255,1) 100%)',
        //backgroundColor:'#00A2D9',
        height:'30px',
        //textAlign:'center',
        borderRadius:'100px',
        opacity:'1',
        alignItems:'center'
    },
    text:{
        color:'white',
    }
})