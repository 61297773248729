import loto from "../loto.json"
import top from "../top.json"
import moment from "moment"

export const rechercheNumero = (numero1, numero2, numero3, numero4, numero5) =>
  loto
    .filter(
      (e) =>
        e.boule1 === numero1 ||
        e.boule2 === numero1 ||
        e.boule3 === numero1 ||
        e.boule4 === numero1 ||
        e.boule5 === numero1 ||
        e.boule6 === numero1
    )
    .filter(
      (e) =>
        e.boule1 === numero2 ||
        e.boule2 === numero2 ||
        e.boule3 === numero2 ||
        e.boule4 === numero2 ||
        e.boule5 === numero2 ||
        e.boule6 === numero2
    )
    .filter(
      (e) =>
        e.boule1 === numero3 ||
        e.boule2 === numero3 ||
        e.boule3 === numero3 ||
        e.boule4 === numero3 ||
        e.boule5 === numero3 ||
        e.boule6 === numero3
    )
    .filter(
      (e) =>
        e.boule1 === numero4 ||
        e.boule2 === numero4 ||
        e.boule3 === numero4 ||
        e.boule4 === numero4 ||
        e.boule5 === numero4 ||
        e.boule6 === numero4
    )
    .filter(
      (e) =>
        e.boule1 === numero5 ||
        e.boule2 === numero5 ||
        e.boule3 === numero5 ||
        e.boule4 === numero5 ||
        e.boule5 === numero5 ||
        e.boule6 === numero5
    )
//{
//   const choix1 = returnSelectedNumbers(loto, numero1)
//   console.log(choix1)
//   if (numero2 > 0) return returnSelectedNumbers(choix1, numero2)
//   else return choix1
// }

// const returnSelectedNumbers = (tab, numero) =>
//   tab.filter(
//     (e) =>
//       e.boule1 === numero ||
//       e.boule2 === numero ||
//       e.boule3 === numero ||
//       e.boule4 === numero ||
//       e.boule5 === numero ||
//       e.boule6 === numero
//   )

export const numeroListe = () => {
  const tab = []
  for (let i = 1; i < 50; i++) tab.push(i)
  return tab
}

export const computeTopFlop = (topMoins, topPlus) => {
  for (let i = 0; i < 5; i++) topMoins.push(top[i])
  topMoins.sort(function (a, b) {
    return a.nombre - b.nombre
  })
  for (let i = 44; i < 49; i++) topPlus.push(top[i])
  topPlus.sort(function (a, b) {
    return b.nombre - a.nombre
  })
}
export const recherheDate = (date) => {
  const match = loto.find((e) => {
    return moment(e.date).isSame(date)
  })
  return match
}
